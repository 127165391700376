import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import AuthStore from 'store/Auth/AuthStore'

const NonAuthProvider: React.FC = (): React.ReactElement => {
    const isAuth = AuthStore.isAuth

    if (isAuth) {
        return <Navigate to={AppRoutes.mainPage} />
    }

    return <Outlet />
}
export default observer(NonAuthProvider)
