import classnames from 'classnames'
import { useConfig } from 'hooks/useConfig'
import All from 'assets/icons/all-currencies.svg'
import styles from './styles.module.scss'

interface Props {
    ticker: any
    label: string
    active?: boolean
    protocol?: string
}

const ActionPosBtn: React.FC<Props> = props => {
    const { ticker, label, active, protocol } = props
    const { resourcesUrl } = useConfig()

    return (
        <div
            className={classnames(styles.wrapper, active ? styles.active : '')}
        >
            <div className={styles.icon}>
                {ticker === 'all' ? (
                    <img src={All} alt='' />
                ) : (
                    <img
                        src={`${resourcesUrl}/icons/${ticker.toLowerCase()}.svg`}
                        alt=''
                    />
                )}
            </div>
            <div className={styles.label}>
                <span className={styles.network}>{label}</span>
                {protocol && (
                    <span className={styles.protocol}>{` (${protocol})`}</span>
                )}
            </div>
        </div>
    )
}

export default ActionPosBtn
