import { makeAutoObservable } from 'mobx'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'

class AuthStore {
    isAuth: boolean = false
    isLoading: boolean = true

    constructor() {
        makeAutoObservable(this)
    }

    async checkAuth(): Promise<void> {
        const accessToken = SessionStorageService.get(LSKeys.accessToken)

        if (!accessToken) {
            console.log(accessToken)
            this.setLoading(false)
            return
        }

        try {
            this.setLoading(true)

            if (accessToken) {
                this.setAuth(true)
                return
            }
        } catch (error) {
            console.error('check payment error', error)
        } finally {
            this.setLoading(false)
        }
    }

    logout(): void {
        SessionStorageService.remove(LSKeys.accessToken)
        this.setAuth(false)
    }

    private setAuth(bool: boolean): void {
        this.isAuth = bool
    }

    private setLoading(bool: boolean): void {
        this.isLoading = bool
    }
}

export default new AuthStore()
