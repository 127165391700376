import { FormikProps, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PosService from 'services/Pos'
import { Terminal, Type } from 'services/Pos/types'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'
import SoftNoteService from 'services/SoftNote/SoftNoteService'
import { SoftNoteCurrency } from 'services/SoftNote/types'
import { AppRoutes } from 'routes/AppRoutes'
import PageQrCode from 'pages/QrCode'
import AuthStore from 'store/Auth/AuthStore'
import Loader from 'com/UI/Loader'
import { SimpleEntity } from 'com/shared/form/Select/types'
import TerminalComponent from './component'
import { Values } from './types'
import validationSchema from './validationSchema'

const tips = [5, 10, 15, 20, 25]

const errors: Record<number, string> = {
    10010: 'The service did not give the data '
}

const PageTerminal: React.FC = () => {
    const [viewBtns, setViewBtns] = useState<number>(3)
    const [activeCurrency, setActiveCurrency] = useState<
        SoftNoteCurrency | undefined
    >()
    const [items, setItems] = useState<SoftNoteCurrency[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTip, setActiveTip] = useState<number | undefined>()
    const [info, setInfo] = useState<Terminal>()
    const [types, setTypes] = useState<Type[]>()
    const [options, setOptions] = useState<SimpleEntity[]>()
    const [activeOption, setActiveOption] = useState<SimpleEntity | undefined>(
        options ? options[0] : undefined
    )
    const [paymentPrepareLoading, setPaymentPrepareLoading] =
        useState<boolean>(false)
    const fetchData = async () => {
        setLoading(true)
        try {
        } catch (error) {
            toast.error('Something wrong. Try later')
        } finally {
            setLoading(false)
        }
    }
    const navigate = useNavigate()
    const fetchTerminalInfo = async () => {
        try {
            setLoading(true)
            const responseCurrencies = await SoftNoteService.currencies()
            const filteredCurrecies: SoftNoteCurrency[] =
                responseCurrencies.data.payload.filter(
                    (item: SoftNoteCurrency) => item.isActual
                )
            setItems(filteredCurrecies)
            setActiveCurrency(
                filteredCurrecies.find(
                    (item: SoftNoteCurrency) =>
                        item.key === 'bitcoin-sch256-btc'
                )
            )
            const response = await PosService.getTerminalInfo(
                SessionStorageService.get(LSKeys.hid)
            )
            setTypes(response.data.payload.types)
            setInfo(response.data.payload)
            let options: any = []
            response.data.payload.types.forEach((item: Type) => {
                options.push({
                    value: String(item.id),
                    text: item.name
                })
            })
            if (!options.length) {
                toast.error('Need to create taxes')
                return
            }
            setOptions(options)
            setActiveOption(options[0])
            formik.setFieldValue('category', options[0].value)
        } catch (error: any) {
            if (error.response.data.status === 10010) {
                toast.error(errors[error.response.data.status])
            }
            AuthStore.logout()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchTerminalInfo()
        fetchData()
    }, [])

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            name: '',
            category: options ? options[0].value : '',
            cryptocyrrency: 'bitcoin-sch256-btc',
            tips: '0',
            amount: '',
            discount: 0
        } as Values,
        validationSchema: validationSchema,
        validateOnChange: false,

        onSubmit: async (values: Values) => {
            if (paymentPrepareLoading) return
            try {
                setPaymentPrepareLoading(true)
                const response = await PosService.paymentPrepare(
                    values.cryptocyrrency,
                    {
                        amount: Number(values.amount),
                        paymentType: Number(activeOption?.value),
                        discount: values.discount,
                        valueTips: Number(values.tips)
                    }
                )

                const pageQrCodeData = JSON.parse(
                    JSON.stringify(response.data.payload)
                )
                pageQrCodeData.amount = Number(values.amount)
                pageQrCodeData.paymentType = Number(activeOption?.value)
                pageQrCodeData.discount = values.discount
                pageQrCodeData.name = values.name
                pageQrCodeData.currency = activeCurrency
                SessionStorageService.set(
                    LSKeys.paymentInfo,
                    JSON.stringify(pageQrCodeData)
                )
                navigate(AppRoutes.qr.path)
            } catch (error) {
                toast.error('Something wrong. Try later')
            } finally {
                setPaymentPrepareLoading(false)
            }
        }
    })

    useEffect(() => {
        if (formik.errors) {
            for (const error of Object.values(formik.errors)) {
                toast.error(error)
            }
        }
        formik.setErrors({})
    }, [formik.errors])
    const handleChangeCategory = (e: any) => {
        setActiveOption(e)
        formik.setFieldValue('category', activeOption?.value)
    }

    const clearFunction = (name: string) => {
        formik.setFieldValue(name, 0)
        if (name === 'tips') {
            setActiveTip(undefined)
        }
    }

    const handleClickTip = (val: number) => {
        if (!formik.values.amount) {
            toast.error('Specify the amount')
            return
        }
        if (val === activeTip) {
            setActiveTip(undefined)
            formik.setFieldValue('tips', 0)
            return
        }
        setActiveTip(val)
        formik.setFieldValue('tips', (Number(formik.values.amount) / 100) * val)
    }

    const clickCurrency = (key: SoftNoteCurrency) => {
        setActiveCurrency(key)
        formik.setFieldValue('cryptocyrrency', key.key)
        const decimal = items.find(
            (item: SoftNoteCurrency) => item.key === key.key
        )?.decimal
        if (
            formik.values.amount !== '0' &&
            !Number.isInteger(Number(formik.values.amount))
        ) {
            formik.setFieldValue(
                'tips',
                Number(formik.values.tips).toFixed(decimal).replace(/0*$/, '')
            )
            formik.setFieldValue(
                'amount',
                Number(formik.values.amount).toFixed(decimal).replace(/0*$/, '')
            )
            return
        }
        if (formik.values.amount === '0') {
            formik.setFieldValue('tips', '0')
            formik.setFieldValue('amount', '0')
        }
        formik.setFieldValue('tips', formik.values.tips)
        formik.setFieldValue('amount', formik.values.amount)
    }

    if (loading) return <Loader size='small' />

    return (
        <TerminalComponent
            activeCurrency={activeCurrency}
            activeOption={activeOption}
            activeTip={activeTip}
            clearFunction={clearFunction}
            clickCurrency={clickCurrency}
            currencies={items}
            formik={formik}
            handleChangeCategory={handleChangeCategory}
            handleClickTip={handleClickTip}
            info={info}
            options={options}
            paymentPrepareLoading={paymentPrepareLoading}
            setActiveCurrency={setActiveCurrency}
            setActiveOption={setActiveOption}
            setActiveTip={setActiveTip}
            setViewBtns={setViewBtns}
            tips={tips}
            viewBtns={viewBtns}
        />
    )
}

export default PageTerminal
