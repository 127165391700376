import {
    IconDiscord,
    IconFacebook,
    IconInstagram,
    IconLink,
    IconLinkedin,
    IconTelegram,
    IconTwitter,
    IconYoutube
} from 'com/Icons'
import styles from './styles.module.scss'

const routes = [
    {
        id: 1,
        title: 'Facebook',
        link: 'https://www.facebook.com/Tectum.io/',
        icon: <IconFacebook />
    },
    {
        id: 2,
        title: 'Telegram',
        link: 'https://t.me/tectumglobal',
        icon: <IconTelegram />
    },
    {
        id: 3,
        title: 'LinkedIn',
        link: 'https://www.linkedin.com/showcase/tectum-blockchain',
        icon: <IconLinkedin />
    },
    {
        id: 4,
        title: 'YouTube',
        link: 'https://www.youtube.com/channel/UCn17IrKSqmIFn8illLRR2-g',
        icon: <IconYoutube />
    },
    {
        id: 5,
        title: 'Instagram',
        link: 'https://www.instagram.com/tectum.io/',
        icon: <IconInstagram />
    },
    {
        id: 6,
        title: 'Twitter',
        link: 'https://twitter.com/tectumsocial',
        icon: <IconTwitter />
    },
    {
        id: 7,
        title: 'Discord',
        link: 'https://discord.com/invite/5ZV8r6rGEN',
        icon: <IconDiscord />
    }
]

const Footer: React.FC = (): React.ReactElement => {
    return (
        <footer className={styles.wrapper}>
            <div className={styles.beta}>Beta Version 1.0.4</div>
            <div className={styles.container}>
                {routes.map(item => (
                    <a
                        href={item.link}
                        key={item.id}
                        className={styles.link}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {item.icon}
                        <span>{item.title}</span>
                        <IconLink />
                    </a>
                ))}
            </div>
        </footer>
    )
}
export default Footer
