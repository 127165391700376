import styles from './styles.module.scss'

const InlineLoader: React.FC = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.square}></div>
            <div className={styles.square}></div>
            <div className={styles.square}></div>
            <div className={styles.square}></div>
        </div>
    )
}

export default InlineLoader
