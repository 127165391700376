import { Link } from 'react-router-dom'
import PosLogo from 'assets/imgs/logo.png'
import { AppRoutes } from 'routes/AppRoutes'
import AuthStore from 'store/Auth/AuthStore'
import IconLogout from 'com/Icons/IconLogout'
import styles from './styles.module.scss'

const Header: React.FC = () => {
    return (
        <div className={styles.header}>
            <Link to={AppRoutes.mainPage}>
                {' '}
                <div className={styles.logo}>
                    <img src={PosLogo} alt='' />
                </div>
            </Link>
            <div onClick={() => AuthStore.logout()} className={styles.logOut}>
                <IconLogout />
            </div>
        </div>
    )
}

export default Header
