import { FormikProps } from 'formik'
import { toast } from 'react-toastify'
import { checkAmountValue } from 'utils/checkAmountValue'
import { Terminal } from 'services/Pos/types'
import { SoftNoteCurrency } from 'services/SoftNote/types'
import ActionPosBtn from 'com/UI/action/ActionPosBtn'
import Button from 'com/shared/buttons/Button'
import ButtonTip from 'com/shared/buttons/ButtonTip'
import Field from 'com/shared/form/Field'
import Select from 'com/shared/form/Select'
import { SimpleEntity } from 'com/shared/form/Select/types'
import styles from './styles.module.scss'
import { Values } from './types'

interface Props {
    info: Terminal | undefined
    currencies: SoftNoteCurrency[]
    activeTip: number | undefined
    setActiveTip: (val: number | undefined) => void
    options: SimpleEntity[] | undefined
    activeOption: SimpleEntity | undefined
    setActiveOption: (val: SimpleEntity | undefined) => void
    activeCurrency: SoftNoteCurrency | undefined
    setActiveCurrency: (val: SoftNoteCurrency) => void
    handleChangeCategory: (e: any) => void
    clickCurrency: (ket: SoftNoteCurrency) => undefined | void
    viewBtns: number
    setViewBtns: (val: number) => void
    clearFunction: (val: string) => void
    tips: number[]
    handleClickTip: (val: number) => void
    paymentPrepareLoading: boolean
    formik: FormikProps<Values>
}

const TerminalComponent: React.FC<Props> = props => {
    const {
        currencies,
        info,
        options,
        activeCurrency,
        activeOption,
        activeTip,
        setActiveCurrency,
        setActiveOption,
        setActiveTip,
        handleChangeCategory,
        clickCurrency,
        viewBtns,
        setViewBtns,
        clearFunction,
        tips,
        handleClickTip,
        paymentPrepareLoading,
        formik
    } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.contaner}>
                <div className={styles.title}>Terminal:&nbsp;{info?.name}</div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Field
                            label='Name of the product or service'
                            type='text'
                            name='name'
                            value={formik.values.name}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => formik.setFieldValue('name', e.target.value)}
                        />
                    </div>
                    <div className={styles.col}>
                        {options ? (
                            <Select
                                className={styles.select}
                                options={options}
                                onChange={handleChangeCategory}
                                selected={activeOption?.text}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className={styles.cryptocurrencyBlock}>
                    <div className={styles.subtitile}>
                        Choose a cryptocurrency
                    </div>
                    <div className={styles.rowCryptocurrency}>
                        {currencies.map((el: SoftNoteCurrency, index) => {
                            if (currencies.length === 4) {
                                return (
                                    <div onClick={() => clickCurrency(el)}>
                                        <ActionPosBtn
                                            active={
                                                activeCurrency?.key === el.key
                                            }
                                            key={el.key}
                                            protocol={el.protocol}
                                            label={el.ticker}
                                            ticker={el.ticker}
                                        />
                                    </div>
                                )
                            }
                            if (index < viewBtns) {
                                return (
                                    <div onClick={() => clickCurrency(el)}>
                                        <ActionPosBtn
                                            active={
                                                activeCurrency?.key === el.key
                                            }
                                            key={el.key}
                                            protocol={el.protocol}
                                            label={el.ticker}
                                            ticker={el.ticker}
                                        />
                                    </div>
                                )
                            }
                        })}

                        {currencies.length > 4 && (
                            <div
                                onClick={() =>
                                    setViewBtns(currencies.length ?? 0)
                                }
                            >
                                <ActionPosBtn label='All' ticker='all' />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.price}>
                    <div className={styles.priceTitle}>Price</div>
                    <div className={styles.inputWrap}>
                        <div className={styles.flexGrow}>
                            <input
                                placeholder='0'
                                onChange={e => {
                                    if (!activeCurrency) {
                                        toast.error('Select currency!')
                                        return
                                    }

                                    if (
                                        checkAmountValue(
                                            e.target.value,
                                            activeCurrency.decimal
                                        )
                                    ) {
                                        return
                                    }
                                    let result = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ''
                                    )

                                    if (
                                        result !== '0.' &&
                                        result.length === 2
                                    ) {
                                        result = result.replace(/^0+/, '')
                                    }

                                    formik.setFieldValue('amount', result)
                                }}
                                value={formik.values.amount}
                                type='string'
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.inputTicker}>
                            {activeCurrency?.ticker ?? ''}
                        </div>
                    </div>
                </div>
                <Field
                    clearFunction={() => clearFunction('tips')}
                    name='tips'
                    value={formik.values.tips}
                    label='Tips'
                    clearBtn
                    type='text'
                    onChange={(e: any) => {
                        if (
                            checkAmountValue(
                                e.target.value,
                                activeCurrency?.decimal
                                    ? activeCurrency?.decimal
                                    : 8
                            )
                        ) {
                            return
                        }
                        const result = e.target.value.replace(/[^0-9.]/g, '')
                        formik.setFieldValue('tips', result)
                    }}
                />
                <div className={styles.tipsBtns}>
                    {tips.map((el: number) => (
                        <div onClick={() => handleClickTip(el)}>
                            <ButtonTip value={el} active={el === activeTip} />
                        </div>
                    ))}
                </div>
                {/* <div className={styles.rowField}>
                <Field
                    clearFunction={() => clearFunction('price')}
                    name='price'
                    value={formik.values.price}
                    label='Стоимость доставки'
                    clearBtn
                    onChange={(e: any) =>
                        formik.setFieldValue('price', e.target.value)
                    }
                />
            </div> */}
                {/* <div className={styles.rowField}>
                <Field
                    clearFunction={() => clearFunction('price')}
                    name='discount'
                    value={0}
                    label='Discount'
                    type='number'
                    onChange={(e: any) =>
                        formik.setFieldValue('disccount', e.target.value)
                    }
                />
            </div> */}
                <div className={styles.centered}>
                    <Button
                        width='80%'
                        size='large'
                        isLoading={paymentPrepareLoading}
                        onClick={formik.handleSubmit}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TerminalComponent
