import { ToastContainer } from 'react-toastify'
import styles from './styles.module.scss'
import 'react-toastify/dist/ReactToastify.min.css'

const AppToastContainer: React.FC = (): React.ReactElement => {
    return (
        <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
            bodyClassName={styles.text}
        />
    )
}
export default AppToastContainer
