import cls from 'classnames'
import React, { useEffect, useRef } from 'react'
import styles from './styles.module.scss'

interface Props {
    size?: 'small' | 'medium'
}

const Loader: React.FC<Props> = (props: Props) => {
    const { size } = props

    let classname = styles.wrapper

    if (size === 'small') classname = cls(classname, styles.smallSize)

    const svgRef = useRef(null)

    const options = {
        stripLength: 20,
        increasePerTurnover: 5,
        color: 'yellow',
        turnoverInterval: 1000
    }

    useEffect(() => {
        if (svgRef && svgRef.current) {
            const svgItem = svgRef.current as HTMLElement
            const paths = Array.from(svgItem.querySelectorAll('path')).reverse()

            let stripLength = options.stripLength
            let increasePerTurnover = options.increasePerTurnover
            let turnover = 0

            const animation = () => {
                let beginElements = 0

                paths.forEach((item, index) => {
                    setTimeout(() => {
                        item.style.fill = '#000'
                        if (paths[index + stripLength]) {
                            paths[index + stripLength].style.fill =
                                options.color
                            for (let t = 0; t <= increasePerTurnover; t++) {
                                paths[index + stripLength - t].style.fill =
                                    options.color
                            }
                            beginElements = 0
                        } else {
                            paths[beginElements].style.fill = options.color
                            beginElements++
                        }
                    }, 10 + 10 * index)
                })

                if (turnover < 8) {
                    stripLength += increasePerTurnover
                }

                if (turnover >= 8) {
                    stripLength -= increasePerTurnover
                }

                if (turnover >= 12) {
                    turnover = 0
                }

                turnover++
            }

            animation()

            const interval = setInterval(animation, options.turnoverInterval)

            return () => {
                clearInterval(interval)
            }
        }
    }, [svgRef])

    return (
        <div className={classname}>
            <svg
                className={styles.svg}
                ref={svgRef}
                width='265'
                height='122'
                viewBox='0 0 265 122'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#clip0_314_756)'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M38.7781 20.1768L38.7599 20.1841L38.7054 20.2095L38.6509 20.235L38.6109 20.2568L38.5964 20.264L38.5419 20.2895L38.4873 20.3186L38.4764 20.3222L38.4328 20.344L38.3238 20.4022L38.2693 20.4312L38.2547 20.4385L23.2793 7.93841C24.3588 7.02607 25.4929 6.17916 26.6742 5.40494C29.1677 3.76928 31.872 2.45348 34.6962 1.53024C35.0197 1.42483 35.3469 1.32305 35.674 1.22855L35.6813 1.22491C35.9248 1.15585 36.1683 1.08679 36.4119 1.02136L42.2312 19.0609L42.1585 19.0755L41.9077 19.1227H41.9004L41.8241 19.1373L41.6569 19.1736H41.6496L41.5733 19.1918L41.4061 19.2318L41.3988 19.2354L41.3225 19.2536C41.1844 19.2899 41.0426 19.3263 40.9045 19.3662L40.8936 19.3699L40.7373 19.4171L40.7264 19.4208C40.5919 19.4608 40.4574 19.5044 40.3229 19.5516L40.312 19.5553L40.243 19.5807L40.232 19.5843L39.9994 19.6679L39.9849 19.6716L39.7523 19.7588L39.7377 19.7624L39.6687 19.7879L39.6541 19.7915C39.3597 19.9078 39.0689 20.0387 38.7781 20.1768V20.1768Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M38.2514 20.4421L38.2114 20.4639L38.2041 20.4676L38.1569 20.493L38.1024 20.5221L38.0733 20.5366L38.0478 20.5512L37.9933 20.5802L37.9388 20.6093L37.8843 20.6384L37.8297 20.6675L37.8043 20.682L37.7752 20.7002L37.7207 20.7329L37.6734 20.762L37.6698 20.7656L37.5099 20.8601L37.4554 20.8928L37.4117 20.9183L37.4008 20.9256L37.3463 20.9583L37.2918 20.991L37.2773 20.9983L37.2373 21.0237L37.1864 21.0564L37.15 21.0782L37.1355 21.0891L37.0846 21.1218L36.9792 21.1909L36.9283 21.2236L36.8956 21.2454L36.8738 21.26L36.8229 21.2963L36.772 21.3327H36.7684L36.7175 21.3654L36.6666 21.4017L36.6412 21.4199L36.6157 21.4381L36.5648 21.4744L36.4631 21.5471L36.4122 21.5835L36.3904 21.598L36.3613 21.6198L36.3104 21.6562L36.2595 21.6925L36.2086 21.7288L36.1577 21.7652L36.1432 21.7761L36.1068 21.8015L36.056 21.8415L36.0196 21.8706L36.0087 21.8815L35.9578 21.9215L35.9069 21.9615L35.896 21.9687L35.856 22.0015L35.8052 22.0414L35.7543 22.0814L35.7034 22.1214L35.6961 22.1287L35.6052 22.2014L35.558 22.2414L35.5325 22.2632L35.5107 22.2813L35.4635 22.3213L35.4162 22.3613L35.2236 22.5249L35.1872 22.5576L35.1763 22.5649L35.1218 22.6157L15.9082 16.582C17.0532 14.6737 18.3835 12.8817 19.8665 11.2352C20.917 10.0684 22.0474 8.9743 23.2396 7.96746L23.2687 7.94202L38.2441 20.4421H38.2514Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M35.133 22.6158C34.9803 22.754 34.8276 22.8921 34.6786 23.0338L34.6459 23.0666L34.635 23.0775L34.4569 23.2519L34.4351 23.2737L34.3697 23.3392L34.3297 23.3792L34.326 23.3828L34.2824 23.4264L34.2388 23.47L34.2279 23.4809L34.1952 23.5136L34.1516 23.5573L34.1225 23.5863L34.108 23.6009L34.0643 23.6445L34.0207 23.6881L34.0171 23.6917L33.9771 23.7354L33.9335 23.7826L33.9153 23.8044L33.8935 23.8299L33.8499 23.8771L33.8135 23.9171L33.8063 23.9244L33.7663 23.9716L33.7263 24.0189L33.719 24.0298L33.6863 24.0661L33.6463 24.1134L33.6209 24.1425L33.6063 24.1606L33.5664 24.2079L33.5264 24.2551L33.4537 24.3424L33.4464 24.3496L33.4319 24.3678L33.4064 24.3969L33.3665 24.4442L33.3374 24.4805L33.3301 24.4914L33.2901 24.5387L33.2501 24.5859L33.2429 24.5932L33.2102 24.6332L33.1702 24.6804L33.1484 24.7059L33.1302 24.7277L33.0902 24.7749L33.0139 24.8731L32.9775 24.9203L32.9666 24.9385L32.9412 24.9712L32.9048 25.0221L32.8794 25.0584L32.8685 25.073L32.8321 25.1239L32.7958 25.1747L32.7922 25.182L32.7231 25.2765L32.7049 25.302L32.6686 25.3529L32.6213 25.4219L32.614 25.4292L32.5668 25.4982H12.2373C12.4627 24.5314 12.4299 24.7567 12.4881 24.5496C13.0261 22.6122 13.7494 20.7366 14.6326 18.9447L14.669 18.872C15.0579 18.0941 15.4759 17.3308 15.9194 16.5857L35.133 22.6195V22.6158Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M32.5589 25.4945L32.5516 25.5018L32.5262 25.5381L32.4971 25.5781L32.4462 25.6544L32.4426 25.6581L32.3953 25.7308L32.3444 25.8071L32.2935 25.8834L32.2826 25.898L32.2426 25.9598L32.2027 26.0216L32.1918 26.0397L32.1409 26.1197L32.1227 26.1488L32.09 26.1997L32.0464 26.2724L32.0427 26.2796L31.9955 26.3596L31.97 26.3996L31.9482 26.4396L31.901 26.5195L31.8973 26.5268L31.8574 26.5995L31.8283 26.654L31.8137 26.6795L31.7701 26.7594L31.7592 26.7776L31.7265 26.8394L31.6902 26.9048L31.6829 26.9194L31.6393 26.9993L31.6211 27.032L31.5956 27.0829L31.5557 27.1629V27.1665L31.5157 27.2465L31.4939 27.2901L31.4757 27.3265L31.4357 27.4101L31.4321 27.421L31.3957 27.4937L31.3703 27.5518L31.3594 27.5773L31.3194 27.6609L31.3085 27.6827L31.2831 27.7408L31.2503 27.8099L31.2467 27.8208L31.2104 27.9044L31.1958 27.9407C30.5815 29.3801 30.4616 30.187 30.1962 31.6664H29.462L11.0771 31.6482L11.8695 27.2356C11.9495 26.7885 12.0404 26.3451 12.1421 25.9052L12.2367 25.5018H32.5661L32.5589 25.4945Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M29.0514 38.0455L9.92871 38.0274L11.0737 31.641L30.1964 31.6592L29.0514 38.0455Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M27.9033 44.4355L8.78418 44.4137L9.92914 38.0273L29.0519 38.0455L27.9033 44.4355Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M26.7578 50.8219L7.63867 50.8001L8.78364 44.4137L27.9027 44.4355L26.7578 50.8219Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M25.613 57.2082L6.49023 57.1864L7.63883 50.8L26.7579 50.8219L25.613 57.2082Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M25.0138 60.5341L24.4577 63.6455L5.33496 63.6236L5.89109 60.5341L6.49447 57.1864L25.6136 57.2046L25.0175 60.5341H25.0138Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.33442 63.6237L24.4572 63.6418L23.3122 70.0318L4.18945 70.01L5.33442 63.6237Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M4.18989 70.0101L23.3126 70.0283L22.1677 76.4183L3.04492 76.3965L4.18989 70.0101Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M3.04438 76.3965L22.1671 76.4147L21.0185 82.8047L1.89941 82.7828L3.04438 76.3965Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M1.89985 82.7828L21.0189 82.8046L19.8776 89.1692H0.754883L1.89985 82.7828Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.00976562 95.5737V95.5556L17.9839 95.5737H0.00976562V95.5737ZM18.0275 95.5737L0.00976562 95.5556C0.0170352 94.0289 0.180602 92.3496 0.511369 90.5177L0.754902 89.1692H1.12202L19.874 89.1874L19.038 93.8399C18.9544 94.3015 18.9108 94.8613 18.9035 95.501C18.9071 95.5228 18.9108 95.5483 18.9144 95.5774H18.0239L18.0275 95.5737Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.856405 102.473C0.282105 100.295 0.00585938 98.02 0.00585938 95.6428V95.5737H17.98H18.7433H18.0236H18.9141L18.9178 95.6464C18.9178 96.228 18.9723 96.7841 19.0813 97.3003L0.856405 102.469V102.473Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.28364 111.341C3.14274 108.586 1.68882 105.631 0.856445 102.473L19.0814 97.3038C19.2631 98.1944 19.5939 98.9795 20.0519 99.6337L5.28364 111.341V111.341Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12.8073 117.753C10.1175 116.227 7.63129 114.209 5.74846 111.923L5.54127 111.665L5.2832 111.338L20.0514 99.6301C20.1314 99.7464 20.215 99.8555 20.3059 99.9645C20.753 100.506 21.3091 100.968 21.9633 101.328L12.8073 117.75V117.753Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M14.901 118.822L14.6029 118.687V118.684C13.9959 118.4 13.3962 118.088 12.8037 117.753L21.9598 101.331C22.5704 101.669 23.2647 101.916 24.0244 102.058L20.7458 120.596L20.724 120.592C18.6667 120.218 16.7184 119.618 14.8974 118.822H14.901Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M30.5531 102.171L27.1545 121.068H25.6824C24.1122 121.068 22.462 120.908 20.75 120.599L24.0831 102.073C24.4684 102.138 25.0027 102.175 25.6824 102.175H30.5531V102.171Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M37.0556 102.171L33.6571 121.068H27.1689L30.5675 102.171H37.0556Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M43.5615 102.171L40.1629 121.068H33.6748L37.0697 102.171H43.5615Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M50.0644 102.171L46.6659 121.068H40.1777L43.5763 102.171H50.0644Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M56.5703 102.171L53.1717 121.068H46.6836L50.0785 102.171H56.5703Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M63.0732 102.171L59.6747 121.068H53.1865L56.5851 102.171H63.0732Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M69.58 102.171L66.1815 121.068H59.6934L63.0919 102.171H69.58Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M76.082 102.171L72.6834 121.068H66.1953L69.5939 102.171H76.082Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M82.5888 102.171L79.1903 121.068H72.7021L76.1007 102.171H82.5888Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M89.0918 102.171L85.6932 121.068H79.2051L82.6036 102.171H89.0918Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M95.5976 102.171L92.1991 121.068H85.7109L89.1095 102.171H95.5976Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M102.101 102.171L98.702 121.068H92.2139L95.6124 102.171H102.101Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M108.606 102.171L105.208 121.068H98.7197L102.118 102.171H108.606Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M115.109 102.171L111.711 121.068H105.223L108.621 102.171H115.109Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M121.615 102.171L118.217 121.068H111.729L115.127 102.171H121.615Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M128.118 102.171L124.72 121.068H118.231L121.63 102.171H128.118Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M134.625 102.171L131.226 121.068H124.738L128.137 102.171H134.625Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M141.127 102.171L137.728 121.068H131.24L134.639 102.171H141.127Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M147.634 102.171L144.235 121.068H137.747L141.146 102.171H147.634Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M154.137 102.171L150.738 121.068H144.25L147.649 102.171H154.137Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M160.643 102.171L157.244 121.068H150.756L154.154 102.171H160.643Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M167.145 102.171L163.747 121.068H157.259L160.657 102.171H167.145Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M173.651 102.171L170.253 121.068H163.765L167.163 102.171H173.651Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M180.154 102.171L176.756 121.068H170.268L173.666 102.171H180.154Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M186.66 102.171L183.262 121.068H176.773L180.172 102.171H186.66Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M193.163 102.171L189.765 121.068H183.276L186.675 102.171H193.163Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M199.67 102.171L196.271 121.068H189.783L193.182 102.171H199.67Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M206.172 102.171L202.773 121.068H196.285L199.684 102.171H206.172Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M212.679 102.171L209.28 121.068H202.792L206.191 102.171H212.679Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M219.182 102.171L215.783 121.068H209.295L212.693 102.171H219.182Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M222.446 102.007L228.265 120.047C224.194 121.057 221.893 121.086 215.769 121.068L219.185 102.171C220.308 102.24 221.392 102.175 222.446 102.011V102.007Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M225.917 100.891L225.935 100.884L225.99 100.859L226.044 100.833L226.084 100.812L226.099 100.804L226.153 100.779L226.208 100.75L226.219 100.746L226.262 100.724L226.371 100.666L226.426 100.637L226.44 100.63L241.416 113.13C240.336 114.042 239.202 114.889 238.021 115.663C235.527 117.299 232.823 118.615 229.999 119.538C229.675 119.643 229.348 119.745 229.021 119.84L229.014 119.843C228.77 119.912 228.527 119.981 228.283 120.047L222.464 102.007L222.537 101.993L222.787 101.946H222.795L222.871 101.931L223.038 101.895H223.045L223.122 101.876L223.289 101.837L223.296 101.833L223.373 101.815C223.511 101.778 223.652 101.742 223.791 101.702L223.801 101.698L223.958 101.651L223.969 101.648C224.103 101.608 224.238 101.564 224.372 101.517L224.383 101.513L224.452 101.488L224.463 101.484L224.696 101.4L224.71 101.397L224.943 101.309L224.957 101.306L225.026 101.28L225.041 101.277C225.335 101.16 225.626 101.03 225.917 100.891V100.891Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M226.439 100.63L226.479 100.608L226.487 100.604L226.534 100.579L226.588 100.55L226.618 100.535L226.643 100.521L226.698 100.492L226.752 100.462L226.807 100.433L226.861 100.404L226.887 100.39L226.916 100.372L226.97 100.339L227.017 100.31L227.021 100.306L227.181 100.212L227.235 100.179L227.279 100.154L227.29 100.146L227.345 100.114L227.399 100.081L227.414 100.074L227.454 100.048L227.504 100.015L227.541 99.9936L227.555 99.9827L227.606 99.95L227.712 99.8809L227.763 99.8482L227.795 99.8264L227.817 99.8118L227.868 99.7755L227.919 99.7391H227.922L227.973 99.7064L228.024 99.6701L228.05 99.6519L228.075 99.6337L228.126 99.5974L228.228 99.5247L228.279 99.4883L228.3 99.4738L228.33 99.452L228.38 99.4156L228.431 99.3793L228.482 99.3429L228.533 99.3066L228.548 99.2957L228.584 99.2702L228.635 99.2303L228.671 99.2012L228.682 99.1903L228.733 99.1503L228.784 99.1103L228.795 99.1031L228.835 99.0703L228.886 99.0304L228.937 98.9904L228.987 98.9504L228.995 98.9431L229.086 98.8704L229.133 98.8304L229.158 98.8086L229.18 98.7905L229.227 98.7505L229.275 98.7105L229.467 98.5469L229.504 98.5142L229.515 98.5069L229.569 98.4561L248.783 104.49C247.638 106.398 246.307 108.19 244.824 109.837C243.774 111.003 242.643 112.098 241.451 113.104L241.422 113.13L226.447 100.63H226.439Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M229.562 98.4561C229.715 98.318 229.868 98.1799 230.017 98.0381L230.05 98.0054L230.06 97.9945L230.239 97.82L230.26 97.7982L230.326 97.7328L230.366 97.6928L230.369 97.6892L230.413 97.6456L230.457 97.6019L230.468 97.591L230.5 97.5583L230.544 97.5147L230.573 97.4856L230.588 97.4711L230.631 97.4275L230.675 97.3839L230.678 97.3802L230.718 97.3366L230.762 97.2894L230.78 97.2676L230.802 97.2421L230.846 97.1949L230.882 97.1549L230.889 97.1476L230.929 97.1004L230.969 97.0531L230.976 97.0422L231.009 97.0058L231.049 96.9586L231.075 96.9295L231.089 96.9113L231.129 96.8641L231.169 96.8168L231.242 96.7296L231.249 96.7223L231.264 96.7042L231.289 96.6751L231.329 96.6278L231.358 96.5915L231.365 96.5806L231.405 96.5333L231.445 96.4861L231.453 96.4788L231.485 96.4388L231.525 96.3916L231.547 96.3661L231.565 96.3443L231.605 96.2971L231.682 96.1989L231.718 96.1517L231.729 96.1335L231.754 96.1008L231.791 96.0499L231.816 96.0135L231.827 95.999L231.863 95.9481L231.9 95.8972L231.903 95.89L231.972 95.7954L231.991 95.77L232.027 95.7191L232.074 95.6501L232.081 95.6428L232.129 95.5737H252.458C252.233 96.5406 252.266 96.3152 252.207 96.5224C251.669 98.4598 250.946 100.335 250.063 102.127L250.026 102.2C249.638 102.978 249.22 103.741 248.776 104.486L229.562 98.4525V98.4561Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M232.132 95.5736L232.14 95.5664L232.165 95.53L232.194 95.49L232.245 95.4137L232.249 95.4101L232.296 95.3374L232.347 95.261L232.398 95.1847L232.409 95.1702L232.449 95.1084L232.488 95.0466L232.499 95.0284L232.55 94.9485L232.568 94.9194L232.601 94.8685L232.645 94.7958L232.648 94.7885L232.696 94.7086L232.721 94.6686L232.743 94.6286L232.79 94.5486L232.794 94.5414L232.834 94.4687L232.863 94.4141L232.877 94.3887L232.921 94.3087L232.932 94.2906L232.965 94.2288L233.001 94.1633L233.008 94.1488L233.052 94.0688L233.07 94.0361L233.096 93.9852L233.135 93.9053V93.9016L233.175 93.8217L233.197 93.778L233.215 93.7417L233.255 93.6581L233.259 93.6472L233.295 93.5745L233.321 93.5163L233.332 93.4909L233.372 93.4073L233.383 93.3855L233.408 93.3273L233.441 93.2583L233.444 93.2474L233.481 93.1638L233.495 93.1274C234.11 91.688 234.23 90.8811 234.495 89.4017H235.229L253.614 89.4199L252.822 93.8326C252.742 94.2796 252.651 94.7231 252.549 95.1629L252.454 95.5664H232.125L232.132 95.5736Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M235.644 83.0227L254.763 83.0409L253.618 89.4309L234.495 89.4091L235.644 83.0227Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M236.789 76.6364L255.911 76.6582L254.763 83.0445L235.644 83.0227L236.789 76.6364Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M237.933 70.25L257.056 70.2682L255.907 76.6582L236.788 76.6364L237.933 70.25Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M239.079 63.8636L258.201 63.8818L257.056 70.2718L237.934 70.25L239.079 63.8636Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M239.678 60.5341L240.234 57.4264L259.357 57.4446L258.801 60.5341L258.201 63.8854L239.078 63.8636L239.678 60.5341Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M259.357 57.4482L240.234 57.4264L241.383 51.04L260.502 51.0582L259.357 57.4482Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M260.502 51.0619L241.383 51.04L242.528 44.6537L261.647 44.6719L260.502 51.0619Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M261.646 44.6755L242.527 44.6537L243.672 38.2673L262.795 38.2891L261.646 44.6755Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M262.796 38.289L243.673 38.2672L244.814 31.899H263.941L262.796 38.289Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M264.686 25.4946V25.5128L246.711 25.4946H264.686ZM246.668 25.4946L264.686 25.5128C264.678 27.0394 264.515 28.7187 264.184 30.5506L263.94 31.8991H263.573L244.821 31.881L245.657 27.2284C245.741 26.7668 245.785 26.207 245.792 25.5673C245.788 25.5455 245.785 25.52 245.781 25.491H246.671L246.668 25.4946Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M263.835 18.5957C264.41 20.773 264.686 23.0483 264.686 25.4255V25.4946H246.712H245.948H246.668H245.778L245.774 25.4219C245.774 24.8403 245.719 24.2842 245.61 23.768L263.835 18.5993V18.5957Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M259.408 9.73035C261.549 12.4855 263.003 15.4406 263.835 18.5993L245.61 23.768C245.428 22.8775 245.098 22.0923 244.64 21.4381L259.408 9.73035V9.73035Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M251.888 3.31494C254.577 4.84156 257.064 6.85888 258.946 9.14518L259.154 9.40325L259.412 9.73038L244.643 21.4381C244.563 21.3218 244.48 21.2127 244.389 21.1037C243.942 20.5621 243.386 20.1005 242.731 19.7406L251.888 3.31858V3.31494Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M249.79 2.24632L250.088 2.38081V2.38444C250.695 2.66796 251.295 2.98055 251.888 3.31496L242.732 19.737C242.121 19.399 241.427 19.1518 240.667 19.0101L243.946 0.472534L243.967 0.476169C246.025 0.850554 247.973 1.4503 249.794 2.24632H249.79Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M234.143 18.901L237.541 0.00366211H239.013C240.583 0.00366211 242.234 0.163594 243.946 0.472553L240.613 18.9992C240.227 18.9338 239.693 18.8974 239.013 18.8974H234.143V18.901Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M227.636 18.901L231.034 0.00366211H237.522L234.124 18.901H227.636Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M221.133 18.901L224.531 0.00366211H231.019L227.621 18.901H221.133Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M214.627 18.901L218.026 0.00366211H224.514L221.115 18.901H214.627Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M208.124 18.901L211.523 0.00366211H218.011L214.612 18.901H208.124Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M201.618 18.901L205.017 0.00366211H211.505L208.106 18.901H201.618Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M195.115 18.901L198.514 0.00366211H205.002L201.603 18.901H195.115Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M188.608 18.901L192.007 0.00366211H198.495L195.097 18.901H188.608Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M182.106 18.901L185.505 0.00366211H191.993L188.595 18.901H182.106Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M175.6 18.901L178.998 0.00366211H185.486L182.088 18.901H175.6Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M169.098 18.901L172.496 0.00366211H178.984L175.586 18.901H169.098Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M162.591 18.901L165.989 0.00366211H172.478L169.079 18.901H162.591Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M156.088 18.901L159.486 0.00366211H165.975L162.576 18.901H156.088Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M149.582 18.901L152.981 0.00366211H159.469L156.07 18.901H149.582Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M143.079 18.901L146.478 0.00366211H152.966L149.567 18.901H143.079Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M136.573 18.901L139.972 0.00366211H146.46L143.061 18.901H136.573Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M130.07 18.901L133.469 0.00366211H139.957L136.558 18.901H130.07Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M123.563 18.901L126.962 0.00366211H133.45L130.052 18.901H123.563Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M117.062 18.901L120.46 0.00366211H126.948L123.55 18.901H117.062Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M110.555 18.901L113.953 0.00366211H120.441L117.043 18.901H110.555Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M104.053 18.901L107.451 0.00366211H113.939L110.541 18.901H104.053Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M97.5459 18.901L100.944 0.00366211H107.433L104.034 18.901H97.5459Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M91.043 18.901L94.4415 0.00366211H100.93L97.5311 18.901H91.043Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M84.5371 18.901L87.9357 0.00366211H94.4238L91.0252 18.901H84.5371Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M78.0342 18.901L81.4327 0.00366211H87.9209L84.5223 18.901H78.0342Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M71.5283 18.901L74.9269 0.00366211H81.415L78.0165 18.901H71.5283Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M65.0254 18.901L68.4239 0.00366211H74.9121L71.5135 18.901H65.0254Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M58.5186 18.901L61.9171 0.00366211H68.4052L65.0067 18.901H58.5186Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M52.0166 18.901L55.4151 0.00366211H61.9033L58.5047 18.901H52.0166Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M45.5098 18.901L48.9083 0.00366211H55.3964L51.9979 18.901H45.5098Z'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M42.2314 19.061L36.4121 1.02143C40.4831 0.0109552 42.7839 -0.0181233 48.9086 5.07806e-05L45.4919 18.8974C44.3687 18.8284 43.2855 18.8938 42.2314 19.0574V19.061Z'
                    />
                </g>
                <defs>
                    <clipPath id='clip0_314_756'>
                        <rect width='264.691' height='121.072' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

export default Loader
