import { NavigateFunction } from 'react-router-dom'
import { formatCryptoBalance } from 'utils/formatCryptoBalance'
import { PaymentPrepare } from 'services/Pos/types'
import { SoftNoteCurrency } from 'services/SoftNote/types'
import Button from 'com/shared/buttons/Button'
import styles from './styles.module.scss'

interface Props {
    loading: boolean
    Canvas: any
    code: string
    walletUrl: string
    copyClick: () => void
    info: PaymentPrepare | undefined
    currency: SoftNoteCurrency | undefined
    tax: number
    checkPayment: (tokkenPay: string) => void
    navigate: NavigateFunction
    сheckPaymentLoading: boolean
}

const QrCodeComponent: React.FC<Props> = props => {
    const {
        Canvas,
        loading,
        code,
        walletUrl,
        copyClick,
        info,
        currency,
        tax,
        checkPayment,
        navigate,
        сheckPaymentLoading
    } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {!loading && (
                    <>
                        <div className={styles.title}>QR #{code}</div>
                        <div className={styles.flex}>
                            <div className={styles.qr}>
                                <Canvas
                                    text={`${walletUrl}payment/${code}`}
                                    options={{
                                        margin: 2,
                                        width: 285,
                                        color: {
                                            dark: '#000000',
                                            light: '#ffffff'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div onClick={copyClick} className={styles.btn}>
                            <Button width='100%' size='large'>
                                Copy
                            </Button>
                        </div>
                    </>
                )}

                <div className={styles.table}>
                    <div className={styles.tableRow}>
                        <div className={styles.tableCol}>Amount:</div>
                        <div
                            className={styles.tableCol}
                        >{`${formatCryptoBalance(
                            info?.value!,
                            currency?.decimal!
                        )} ${currency?.ticker}`}</div>
                    </div>
                    <div className={styles.tableRow}>
                        <div className={styles.tableCol}>
                            {info?.tax.result
                                ? 'Tax Added On:'
                                : 'Tax Deducted:'}
                        </div>
                        <div
                            className={styles.tableCol}
                        >{`${formatCryptoBalance(tax, currency?.decimal!)} ${
                            currency?.ticker
                        }`}</div>
                    </div>
                    <div className={styles.tableRow}>
                        <div className={styles.tableCol}>Tips:</div>
                        <div
                            className={styles.tableCol}
                        >{`${formatCryptoBalance(
                            info?.tips!,
                            currency?.decimal!
                        )} ${currency?.ticker}`}</div>
                    </div>
                    {/* <div className={styles.tableRow}>
                    <div className={styles.tableCol}>Discount:</div>
                    <div
                        className={styles.tableCol}
                    >{`${info?.discount} ${currecy?.ticker}`}</div>
                </div> */}
                </div>
                <div className={styles.price}>
                    <div className={styles.priceCrypto}>{`${formatCryptoBalance(
                        info?.result!,
                        currency?.decimal!
                    )} ${currency?.ticker}`}</div>
                    <div className={styles.priceFiat}>{`$${formatCryptoBalance(
                        info?.result! * info?.rate!,
                        4
                    )}`}</div>
                </div>
                <div className={styles.btn}>
                    <Button
                        width='100%'
                        size='large'
                        disabled={сheckPaymentLoading}
                        onClick={() => checkPayment(code)}
                    >
                        Proof of Payment
                    </Button>
                </div>
                <div className={styles.btn}>
                    <Button
                        width='100%'
                        size='large'
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QrCodeComponent
