import { useConfig } from 'hooks/useConfig'
import { useQRCode } from 'next-qrcode'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PosService from 'services/Pos'
import { PageQrCodeData, PaymentPrepare } from 'services/Pos/types'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'
import Loader from 'com/UI/Loader'
import QrCodeComponent from './component'

const PageQrCode: React.FC = () => {
    const [checkPaymentLoading, setCheckPaymentLoading] =
        useState<boolean>(false)
    const { Canvas } = useQRCode()
    const info: PageQrCodeData = JSON.parse(
        SessionStorageService.get(LSKeys.paymentInfo)
    )
    const [loading, setLoading] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')
    const { walletUrl } = useConfig()
    const { t: lang } = useTranslation(['mint', 'common'])
    const navigate = useNavigate()
    const getQrCode = async () => {
        try {
            setLoading(true)
            const response = await PosService.payment(info.currency?.key, {
                name: info.name,
                amount: info.amount,
                paymentType: info.paymentType,
                discount: info.discount,
                valueTips: info?.tips
            })
            setCode(response.data.payload.tokenPay)
        } catch (error) {
            toast.error(
                lang('toasts.errors.somethingWrongTryLater', { ns: 'common' })
            )
        } finally {
            setLoading(false)
        }
    }

    const copyClick = () => {
        navigator.clipboard
            .writeText(`${walletUrl}payment/${code}`)
            .then(() => {
                toast.success(lang('toasts.success.copied', { ns: 'common' }))
            })
            .catch(err => {
                toast.error(lang('toasts.errors.copyError', { ns: 'common' }))
            })
    }

    const checkPayment = async (tokkenPay: string) => {
        try {
            setCheckPaymentLoading(true)
            const response = await PosService.checkPayment(tokkenPay)
            if (response.data.payload.state === 0) {
                toast.warning('Payment is not received!')
            } else {
                toast.success('Payment was successful!')
            }
        } catch (error) {
            toast.error(
                lang('toasts.errors.somethingWrongTryLater', { ns: 'common' })
            )
        } finally {
            setCheckPaymentLoading(false)
        }
    }

    useEffect(() => {
        getQrCode()
    }, [])

    if (!info) return <Loader size='small' />

    const tax: number = info?.tax.result ? info?.tax.result : info?.vat.result

    return (
        <QrCodeComponent
            сheckPaymentLoading={checkPaymentLoading}
            navigate={navigate}
            Canvas={Canvas}
            checkPayment={checkPayment}
            code={code}
            copyClick={copyClick}
            currency={info.currency}
            info={info}
            loading={loading}
            tax={tax}
            walletUrl={walletUrl}
        />
    )
}

export default PageQrCode
