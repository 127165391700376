import axios, { AxiosRequestConfig } from 'axios'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'

const $apiSN = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_SOFTNOTE_API_URL,
    headers: {
        'Content-type': 'application/json'
    }
})

const $apiCloud = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_CLOUD_API_URL,
    headers: {
        'Content-type': 'application/json'
    }
})

const $apiAuth = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
    }
})

const $apiWss = axios.create({
    baseURL: process.env.REACT_APP_WSS
})

function useAccessToken(config: AxiosRequestConfig) {
    const accessToken = SessionStorageService.get(LSKeys.accessToken)

    if (!config.headers) config.headers = {}

    config.headers.Authorization = accessToken

    return config
}

$apiCloud.interceptors.request.use((config: AxiosRequestConfig) =>
    useAccessToken(config)
)
$apiAuth.interceptors.request.use((config: AxiosRequestConfig) =>
    useAccessToken(config)
)

$apiSN.interceptors.request.use((config: AxiosRequestConfig) =>
    useAccessToken(config)
)

export { $apiSN, $apiCloud, $apiAuth, $apiWss }
