import { FormikProps, useFormik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PosService from 'services/Pos'
import { Terminal } from 'services/Pos/types'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'
import AuthStore from 'store/Auth/AuthStore'
import AuthPageComponent from './component'
import { Values } from './types'
import validationSchema from './validationSchema'

const errors: Record<number, string> = {
    10010: 'The service did not give the data',
    10002: 'PIN code is not correct '
}

const AuthPage: React.FC = () => {
    const [info, setInfo] = useState<Terminal | undefined>()
    const [loading, setLoading] = useState<boolean>(false)
    const { id } = useParams()
    const { t: lang } = useTranslation('common')
    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await PosService.getTerminalInfo(id ?? '')
            setInfo(response.data.payload)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            code: ''
        } as Values,
        validationSchema: validationSchema,
        validateOnChange: false,

        onSubmit: async (values: Values) => {
            try {
                const response = await PosService.auth(values.id, values.code)
                SessionStorageService.set(
                    LSKeys.accessToken,
                    response.data.payload.accessToken
                )
                AuthStore.checkAuth()
            } catch (error: any) {
                toast.error(lang('toasts.errors.somethingWrongTryLater'))
            }
        }
    })
    const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const result = e.target.value.replace(/\D/g, '')
        if (String(result).length > 5) {
            return
        }
        formik.setFieldValue('code', result)
        if (result.length === 5) {
            try {
                const response = await PosService.auth(id ?? '', e.target.value)
                SessionStorageService.set(
                    LSKeys.accessToken,
                    response.data.payload.accessToken
                )
                AuthStore.checkAuth()
            } catch (error: any) {
                console.log(error)
                toast.error(errors[error.response.data.status])
            }
            return
        }
    }

    useEffect(() => {
        formik.setFieldValue('id', id)
        SessionStorageService.set(LSKeys.hid, id)
        fetchData()
    }, [])

    return (
        <AuthPageComponent
            changeHandler={changeHandler}
            formik={formik}
            loading={loading}
            info={info}
        />
    )
}

export default observer(AuthPage)
