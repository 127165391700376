import { AppConfig } from './types'

export const useConfig = (): AppConfig => {
    return {
        resourcesUrl: process.env.REACT_APP_RESOURCES_URL!,
        authApiUrl: process.env.REACT_APP_AUTH_API_URL!,
        softnoteApiUrl: process.env.REACT_APP_SOFTNOTE_API_URL!,
        cloudApiUrl: process.env.REACT_APP_CLOUD_API_URL!,
        tectumPassportUrl: process.env.REACT_APP_TECTUM_PASSPORT_URL!,
        passportUrl: process.env.REACT_APP_TECTUM_PASSPORT_URL!,
        wssHost: process.env.REACT_APP_WSS!,
        walletUrl: process.env.REACT_APP_WALLET_URL!
    }
}
