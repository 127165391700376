import IconCloseNotCircle from 'com/Icons/IconCloseNotCircle'
import styles from './styles.module.scss'

interface Props {
    name: string
    type?: string
    value: string | number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    label?: any
    readOnly?: boolean
    disabled?: boolean
    color?: string
    clearBtn?: boolean
    clearFunction?: () => void
    max?: number
    min?: number
}

const Field: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        name,
        type,
        value,
        onChange,
        placeholder,
        label,
        readOnly,
        disabled,
        color,
        clearBtn,
        clearFunction,
        max,
        min
    } = props

    return (
        <div className={styles.wrapper}>
            {label && <label>{label}</label>}

            <input
                max={max}
                min={min}
                style={{ color: color }}
                type={type ? type : 'text'}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                className={styles.input}
                disabled={disabled}
            />
            {clearBtn && (
                <div onClick={clearFunction} className={styles.clearBtn}>
                    <IconCloseNotCircle />
                </div>
            )}
        </div>
    )
}
export default Field
