import { $apiAuth, $apiCloud, $apiSN } from 'api'
import { AxiosResponse } from 'axios'
import { ApiRoutes } from 'routes/ApiRoutes'
import {
    AuthResponse,
    CheckPaymentResponse,
    CurrenciesResponse,
    PaymentArgs,
    PaymentPrepareArgs,
    PaymentPrepareResponse,
    PaymentResponse,
    TerminalResponse
} from './types'

export default class PosService {
    static async currencies(): Promise<AxiosResponse<CurrenciesResponse>> {
        return $apiCloud.get(ApiRoutes.cloud.currencies)
    }
    static async getTerminalInfo(
        id: string
    ): Promise<AxiosResponse<TerminalResponse>> {
        return $apiSN(`${ApiRoutes.getTerminalInfo.path}${id}`)
    }

    static auth(
        hid: string,
        code: string
    ): Promise<AxiosResponse<AuthResponse>> {
        return $apiSN.post(ApiRoutes.auth, { hid, code })
    }

    static async paymentPrepare(
        key: string,
        args: PaymentPrepareArgs
    ): Promise<AxiosResponse<PaymentPrepareResponse>> {
        return $apiSN.post(ApiRoutes.paymentPrepare + key, args)
    }

    static async payment(
        key: string | undefined,
        args: PaymentArgs
    ): Promise<AxiosResponse<PaymentResponse>> {
        return $apiSN.post(ApiRoutes.payment + key, args)
    }

    static async checkPayment(
        tokenPay: string
    ): Promise<AxiosResponse<CheckPaymentResponse>> {
        return $apiSN.get(ApiRoutes.checkPayment + tokenPay)
    }
}
