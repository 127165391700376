import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.string().required('Taxes must be set up'),
    cryptocyrrency: Yup.string().required('Cryptocyrrency is required'),
    tips: Yup.number(),
    amount: Yup.number().min(0.00000001).required('Amount is required'),
    discount: Yup.number().required('Price is required')
})

export default validationSchema
