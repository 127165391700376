import { $apiSN } from 'api'
import { AxiosResponse } from 'axios'
import { ApiRoutes } from 'routes/ApiRoutes'
import { CurrenciesResponse } from './types'

export default class SoftNoteService {
    static async currencies(): Promise<AxiosResponse<CurrenciesResponse>> {
        return $apiSN.get(ApiRoutes.softNote.currencies)
    }
}
