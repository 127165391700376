export const checkAmountValue = (val: string, decimal: number) => {
    let strAfterPoint
    if (val.indexOf('.') !== -1) {
        strAfterPoint = val.slice(val.indexOf('.') + 1, val.length + 1)
        if (strAfterPoint.length > decimal) {
            return true
        }
    }

    return false
}
