export const ApiRoutes = {
    cloud: {
        currencies: 'currencies'
    },
    getTerminalInfo: {
        path: '/terminal/auth/',
        params: ':hid'
    },
    auth: '/terminal/auth/',
    paymentPrepare: '/terminal/payment/prepare/',
    payment: '/terminal/payment/',
    checkPayment: '/terminal/payment/check/',
    softNote: {
        currencies: 'currencies'
    }
}
