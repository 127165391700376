const IconCloseNotCircle: React.FC = () => {
    return (
        <svg
            width='34'
            height='32'
            viewBox='0 0 34 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.4403 10.6666L22.4994 21.3333'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M22.4994 10.6666L11.4403 21.3333'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}

export default IconCloseNotCircle
