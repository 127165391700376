import classNames from 'classnames'
import styles from './styles.module.scss'

interface Props {
    value: number
    active: boolean
}

const ButtonTip: React.FC<Props> = props => {
    const { value, active } = props

    return (
        <div
            className={classNames(styles.tipsBtn, active ? styles.active : '')}
        >{`${value} %`}</div>
    )
}

export default ButtonTip
