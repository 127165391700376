import classnames from 'classnames'
import { Link } from 'react-router-dom'
import InlineLoader from 'com/UI/InlineLoader'
import styles from './styles.module.scss'

interface Props {
    children: React.ReactNode | string
    disabled?: boolean
    isLoading?: boolean
    className?: string
    onClick?: () => void
    type?: 'primary' | 'secondary' | 'common' | 'danger'
    href?: string
    size?: 'large' | 'medium' | 'small'
    width?: string
}

const Button: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        children,
        disabled,
        className,
        isLoading,
        onClick,
        type = 'primary',
        href,
        size = 'small',
        width
    } = props

    let elClassName = classnames(
        styles.button,
        className,
        styles[type],
        styles[size],
        { [styles.disabled]: disabled, [styles.fullWidth]: width === 'full' }
    )

    if (!href) {
        return (
            <button
                style={{
                    width: width
                }}
                onClick={disabled ? () => {} : onClick}
                className={elClassName}
            >
                {isLoading ? <InlineLoader /> : children}
            </button>
        )
    }

    if (href.startsWith('http')) {
        return (
            <a
                style={{
                    width: width
                }}
                href={href}
                className={elClassName}
                target='_blank'
                rel='noreferrer'
            >
                {isLoading ? <InlineLoader /> : children}
            </a>
        )
    }

    return (
        <Link
            style={{
                width: width
            }}
            to={href}
            onClick={disabled ? () => {} : onClick}
            className={elClassName}
        >
            {isLoading ? <InlineLoader /> : children}
        </Link>
    )
}
export default Button
