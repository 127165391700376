const IconLogout: React.FC = () => {
    return (
        <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M18.884 27.9999L24.8093 26.6826C25.8947 26.4426 26.6667 25.4799 26.6667 24.3693V7.68794C26.6667 6.57727 25.8947 5.6146 24.8107 5.3746L18.8853 4.05727C17.404 3.72794 16 4.8546 16 6.37194V25.6866C16 27.2026 17.404 28.3293 18.884 27.9999V27.9999Z'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M20 14.6399V17.3066'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M5.33333 21.3333V24.0559C5.33333 25.5293 6.52666 26.7226 7.99999 26.7226H12'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M5.33333 10.6666V7.99992C5.33333 6.52659 6.52666 5.33325 7.99999 5.33325H12'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M5.33333 15.9999H12'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M9.33333 18.6666L12 15.9999L9.33333 13.3333'
                stroke='white'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}

export default IconLogout
