import { observer } from 'mobx-react-lite'
import { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import AuthProvider from 'routes/AuthProvider'
import NonAuthProvider from 'routes/NonAuthProvider'
import AuthPage from 'pages/AuthPage'
import PageQrCode from 'pages/QrCode'
import PageTerminal from 'pages/Terminal'
import AuthStore from 'store/Auth/AuthStore'
import Layout from 'com/Layout'
import Loader from 'com/UI/Loader'
import AppToastContainer from 'com/shared/AppToastContainer'
import InfoText from 'com/shared/InfoText'
import './App.css'

function App() {
    useEffect(() => {
        AuthStore.checkAuth()
    }, [])
    console.log('test')
    const routes = [
        { path: AppRoutes.auth.pathWithParams, element: <AuthPage /> },
        {
            path: AppRoutes.auth.path,
            element: (
                <InfoText
                    children={
                        <>
                            <span>
                                In order to work with POS you need a terminal
                                link from&nbsp;
                            </span>
                            <a
                                target='_blank'
                                href='https://wallet.softnote.com/pos'
                            >
                                https://wallet.softnote.com/pos
                            </a>
                        </>
                    }
                />
            )
        },
        {
            path: AppRoutes.auth.pathWithParams,
            element: (
                <InfoText
                    children={
                        <>
                            <span>
                                In order to work with POS you need a terminal
                                link from&nbsp;
                            </span>
                            <a
                                target='_blank'
                                href='https://wallet.softnote.com/pos'
                            >
                                https://wallet.softnote.com/pos
                            </a>
                        </>
                    }
                />
            )
        }
    ]
    const authRoutes = [
        { path: AppRoutes.mainPage, element: <PageTerminal /> },
        { path: AppRoutes.qr.path, element: <PageQrCode /> }
    ]

    if (AuthStore.isLoading) {
        return <Loader size='small' />
    }

    return (
        <div className='App'>
            <Suspense fallback={<Loader size='small' />}>
                <Routes>
                    <Route element={<NonAuthProvider />}>
                        {routes.map(item => (
                            <Route
                                key={item.path}
                                path={item.path}
                                element={item.element}
                            />
                        ))}
                    </Route>
                    <Route element={<AuthProvider />}>
                        <Route element={<Layout />}>
                            {authRoutes.map(item => (
                                <Route
                                    key={item.path}
                                    path={item.path}
                                    element={item.element}
                                />
                            ))}
                        </Route>
                    </Route>
                </Routes>
            </Suspense>
            <AppToastContainer />
        </div>
    )
}

export default observer(App)
