import { FormikProps } from 'formik'
import { Terminal } from 'services/Pos/types'
import PosLogo from 'assets/imgs/logo.png'
import Loader from 'com/UI/Loader'
import InfoText from 'com/shared/InfoText'
import Field from 'com/shared/form/Field'
import styles from './styles.module.scss'
import { Values } from './types'

interface Props {
    info: Terminal | undefined
    loading: boolean
    formik: FormikProps<Values>
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const AuthPageComponent: React.FC<Props> = props => {
    const { info, loading, formik, changeHandler } = props

    if (loading) return <Loader size='small' />

    if (!info) {
        return (
            <InfoText children='This terminal does’t exist or it’s been removed' />
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img src={PosLogo} alt='' className={styles.img} />
                </div>
                <div className={styles.title}>Terminal:&nbsp;{info.name}</div>
                {info.state === 1 ? (
                    <div className={styles.working}>
                        <div className={styles.subTitle}>Active</div>
                        <div className={styles.inputWrapper}>
                            <div className={styles.inputWrapperTitile}>
                                Enter PIN Code
                            </div>
                            <Field
                                onChange={changeHandler}
                                name='code'
                                value={formik.values.code}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={styles.notWorking}>
                        <div className={styles.subTitlNot}>Offline</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AuthPageComponent
