import { observer } from 'mobx-react-lite'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import SessionStorageService from 'services/SessionStorage/SessionStorageService'
import { LSKeys } from 'services/SessionStorage/types'
import { AppRoutes } from 'routes/AppRoutes'
import AuthStore from 'store/Auth/AuthStore'

const AuthProvider: React.FC = (): React.ReactElement => {
    const isAuth = AuthStore.isAuth

    if (!isAuth) {
        return (
            <Navigate
                to={AppRoutes.auth.path + SessionStorageService.get(LSKeys.hid)}
            />
        )
    }

    return <Outlet />
}
export default observer(AuthProvider)
