import { RefObject, useEffect } from 'react'

// TODO: remove any
const useOnClickOutside = (
    ref: RefObject<HTMLElement>,
    handler: (event: any) => void
) => {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }
            handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
        // todo: wrap handler (modal onClose prop) with useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])
}

export default useOnClickOutside
