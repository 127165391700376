import { animated, useTransition } from '@react-spring/web'
import classnames from 'classnames'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import { ReactSpringSelectOptions } from 'utils/reactSpringOptions/select'
import IconArrow from 'assets/icons/rounded_triangle.svg'
import styles from './styles.module.scss'
import { SimpleEntity } from './types'

interface Props {
    selected?: string | null
    placeholder?: any
    options: SimpleEntity[]
    onChange: (value: SimpleEntity) => void
    className?: string
    label?: any
    disabled?: boolean
    color?: string
    readonly?: boolean
}

const Select: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        selected,
        options,
        placeholder,
        onChange,
        className,
        label,
        disabled,
        color,
        readonly
    } = props

    const [isOpen, setOpen] = useState<boolean>(false)
    const transition = useTransition(isOpen, ReactSpringSelectOptions)

    const refModel = useRef() as React.MutableRefObject<HTMLDivElement>
    useOnClickOutside(refModel, () => setOpen(false))

    const toggleList = (): void => {
        if (!readonly) {
            setOpen(prev => !prev)
        }
    }

    const handleClickItem = (item: SimpleEntity): void => {
        if (item.value !== selected) {
            onChange(item)
        }

        setOpen(false)
    }

    return (
        <div
            ref={refModel}
            className={classnames(
                styles.wrapper,
                className,
                disabled ? styles.disabled : ''
            )}
        >
            {label && <label>{label}</label>}

            <div
                className={classnames(styles.block, { [styles.open]: isOpen })}
                onClick={toggleList}
                style={{ color: color }}
            >
                {selected &&
                    options.filter(
                        item =>
                            item.value === selected || item.text === selected
                    )[0].text}
                {placeholder && !selected && placeholder}
                {!placeholder && !selected && 'Placeholder'}
                {!readonly && (
                    <img src={IconArrow} alt='arrow' className={styles.arrow} />
                )}
            </div>

            {transition(
                (tStyles, state) =>
                    state && (
                        <animated.div style={tStyles} className={styles.list}>
                            {options.map(item => (
                                <div
                                    key={item.value}
                                    className={classnames(
                                        styles.listItem,
                                        item.value === selected
                                            ? styles.listItemActive
                                            : ''
                                    )}
                                    onClick={() => handleClickItem(item)}
                                >
                                    {item.text}
                                </div>
                            ))}
                        </animated.div>
                    )
            )}
        </div>
    )
}
export default Select
