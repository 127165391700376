import styles from './styles.module.scss'

interface Props {
    children: React.ReactNode | string
}

const InfoText: React.FC<Props> = props => {
    const { children } = props

    return <div className={styles.flex}>{children}</div>
}

export default InfoText
