const SessionStorageService = {
    set: function (key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },

    get: function (key: string): any {
        const value = sessionStorage.getItem(key)

        if (!value) return null

        return JSON.parse(value)
    },

    remove: function (key: string): void {
        sessionStorage.removeItem(key)
    }
}

export default SessionStorageService
