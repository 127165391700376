export const AppRoutes = {
    mainPage: '/',
    qr: {
        path: '/qr/',
        params: ':id',
        pathWithParams: '/qr/:id'
    },
    auth: {
        path: '/auth/',
        params: ':id',
        pathWithParams: '/auth/:id'
    }
}
