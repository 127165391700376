import { Outlet, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import styles from './styles.module.scss'

const Layout: React.FC = (): React.ReactElement => {
    return (
        <div className={styles.wrapper}>
            <Header />
            <div className={styles.container}>
                <Outlet />
            </div>

            <Footer />
        </div>
    )
}
export default Layout
